<template>
  <div class="flex justify-center h-screen pt-7">
    <div class="w-1/2 md:w-1/2">
      <div class="space-y-8">
        <!-- Links for Different User Views -->
        <div class="grid grid-cols-1 md:grid-cols-1 gap-4">
          <div class="p-4 bg-white shadow-md rounded-lg">
            <h3 class="text-lg font-semibold">Products</h3>
            <p>Add Products:</p>
            <router-link to="/myproducts" class="text-blue-500 underline">Add Products</router-link>
          </div>
          <div class="p-4 bg-white shadow-md rounded-lg">
            <h3 class="text-lg font-semibold">Customers</h3>
            <p>Add Customers:</p>
            <router-link to="/services" class="text-blue-500 underline">Add Customer</router-link>
          </div>
          <div class="p-4 bg-white shadow-md rounded-lg">
            <h3 class="text-lg font-semibold">Payments</h3>
            <p>View Customer Payments:</p>
            <router-link to="/payments" class="text-blue-500 underline">View Payments</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  // Add any additional logic or data properties here if needed
}
</script>

<style>
/* Additional styles can be added here */
</style>
