<template>
  <div class="mt-2">
    <div class="card-body mt-2">
      <div v-if="this.alertMessage.length > 0">
        <div class="alert alert-success" role="alert">
          {{ this.alertMessage }}
        </div>
      </div>
      <div v-else></div>
      <div class="row">
        <div class="col-md-5 card bg-light shadow mb-3 mr-3 ml-5">
 
      <form @submit.prevent="">   
            <h2 class="card-title underline text-xl text-center">
              User Access
            </h2>
            <div class="mb-3">
              <label class="form-label">Member UserName</label>
              <input
                type="text"
                class="form-control"
                v-model="this.model.member.username"
                required
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Member Password</label>
              <input
                type="password"
                class="form-control"
                v-model="this.model.member.password"
                required
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Member Role</label>
              <select class="form-control" name="" v-model="this.model.member.role" id="">
                <option>Select role</option>
                <option value="member">Member</option>
                <option value="admin">Admin</option>
                <option value="support">support</option>
              </select>
            </div>
            <div v-if="!requestUpdate">
                <div class="flex-col text-center mb-3">
                    <button  @click="submit" class="btn btn-info col-3 rounded hover:bg-gray-400">
                      Add User
                    </button>
                  </div>
            </div>
            <div v-else>
                <div class="flex-col text-center mb-3">
                    <button @click="update" class="btn btn-info col-3 rounded hover:bg-gray-400">
                      Update User
                    </button>
                  </div>
            </div>
            
            </form>
          </div>
      <div class="col-md-6 card bg-light shadow">
        <table class="table table-bordered table-striped">
            <thead>
            <th>Username</th>
            <th>Role</th>
            <th>Date Created</th>
            <th>Date Updated</th>
            <th>Action</th>
            </thead>
            <tbody v-if="this.users.length > 0">
                <tr v-for="(user, index) in this.users" :key="index">
                    <td>{{ user.username}} </td>
                    <td>{{ user.role}}</td>
                    <td>{{ user.date}}</td>
                    <td>{{ user.date_updated}}</td>
                    <td>
                        <button type="" class="btn btn-success m-1" @click="updateUser(user._id)">Update</button>
                        <button type="" class="btn btn-danger m-1" @click="deleteUser(user._id)"> Delete</button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="10"> Loading....</td>
                </tr>
            </tbody>
        </table>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "addUsers",
  data() {
    return {
      errorList: "",
      alertMessage: "",
      requestUpdate:false,
      users:[],
      responseCode: "",
      model: {
        member: {
          username: "",
          password: "",
          role: "",
        },
      },
      singleuser:{id:""},
      updateuser:{
        id:"",
        username:"",
        password:"",
        role:"",
        date_updated:""
      },
      config:{
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(process.env.VUE_APP_MY_SECRET_USERNAME + ":" +this.$attrsVUE_APP_MY_SECRET_PASSWORD),
          "x-router-id": process.env.VUE_APP_ROUTER_ID,
          "x-source-system": process.env.VUE_APP_SOURCE_SYSTEM,
        },
      },
    };
  },
 mounted() {
    this.getUsers();
 },
  methods: {
    async submit() {
        const config = {
             headers: {
             'Content-Type': 'application/json',
             'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
             'x-router-id': process.env.VUE_APP_ROUTER_ID,
             'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
             }
         };
      const mythis = this;
      await axios
        .post(process.env.VUE_APP_BASE_URL + `/user/v1/create-user`,this.model.member,config)
        .then((response) => {
          this.responseCode = response.data.statusCode;
          this.model.member = {};
          if (this.responseCode === "4000") {
            this.alertMessage = response.data.msg;
            
            this.getUsers();
            setTimeout(function () {
                this.alertMessage ="";
            }, 1000);
            this.model.member = {
                username: "",
                password: "",
                role: "",
            };
          }
          if (this.responseCode === "4001") {
            this.alertMessage = response.data.msg;
            setTimeout(function () {
                this.alertMessage ="";
            }, 1000);
          }
        })
        .catch((error) => {
          if (error.response) {
            mythis.errorList = error.response.data.errors;
          } else if (error.request) {
            console.log(error.response.request);
          } else {
            console.error("Error:", error.message);
          }
        });
    },
    async getUsers(){
        const config = {
             headers: {
             'Content-Type': 'application/json',
             'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
             'x-router-id': process.env.VUE_APP_ROUTER_ID,
             'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
             }
         };
    await axios.get(process.env.VUE_APP_BASE_URL+`/user/v1/users`,config)
    .then(response => {
        this.responseCode=response.data.statusCode;
        if(this.responseCode === "4000"){
            this.users=response.data.data;
        }else if(this.responseCode === "4001"){
            this.users=[];
        }
    })
    .catch(error => {
        console.error('Error:', error);
    });
        
    },
    async updateUser(id){
        const config = {
             headers: {
             'Content-Type': 'application/json',
             'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
             'x-router-id': process.env.VUE_APP_ROUTER_ID,
             'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
             }
         };
        const mythis = this;
        this.singleuser.id=id;
      await axios
        .post(process.env.VUE_APP_BASE_URL + `/user/v1/user`,this.singleuser,config)
        .then((response) => {
          this.responseCode = response.data.statusCode;
          if (this.responseCode === "4000") {
            this.alertMessage = response.data.msg;
            this.requestUpdate=true;
            setTimeout(function () {
                this.alertMessage ="";
            }, 1000);
            this.singleuser.id="";
            this.model.member = {
                username: response.data.data.username,
                password: "",
                role: response.data.data.role,
            };
            this.updateuser.id=id;
          }
          if (this.responseCode === "4001") {
            this.alertMessage = response.data.msg;
            setTimeout(function () {
                this.alertMessage ="";
            }, 1000);
          }
        })
        .catch((error) => {
          if (error.response) {
            mythis.errorList = error.response.data.errors;
          } else if (error.request) {
            console.log(error.response.request);
          } else {
            console.error("Error:", error.message);
          }
        });
    },
    async update(){
        const config = {
             headers: {
             'Content-Type': 'application/json',
             'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
             'x-router-id': process.env.VUE_APP_ROUTER_ID,
             'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
             }
         };
        const mythis = this;
        const d = new Date();
        let time = d.toString();
        this.updateuser.password=this.model.member.password;
        this.updateuser.username=this.model.member.username;
        this.updateuser.role=this.model.member.role;
        this.updateuser.date_updated=time;
      await axios
        .post(process.env.VUE_APP_BASE_URL + `/user/v1/update`,this.updateuser,config)
        .then((response) => {
          this.responseCode = response.data.statusCode;
          console.log(response);
          if (this.responseCode === "4000") {
            this.getUsers();
            this.alertMessage = response.data.msg;
            this.requestUpdate=false;
            setTimeout(function () {
                this.alertMessage ="";
            }, 1000);
            this.singleuser.id="";
            this.model.member = {
                username: "",
                password: "",
                role: "",
            };
          }
          if (this.responseCode === "4001") {
            this.alertMessage = response.data.msg;
            setTimeout(function () {
                this.alertMessage ="";
            }, 1000);
          }
        })
        .catch((error) => {
          if (error.response) {
            mythis.errorList = error.response.data.errors;
          } else if (error.request) {
            console.log(error.response.request);
          } else {
            console.error("Error:", error.message);
          }
        });
    },
    async deleteUser(id){
        const config = {
             headers: {
             'Content-Type': 'application/json',
             'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
             'x-router-id': process.env.VUE_APP_ROUTER_ID,
             'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
             }
         };
         this.singleuser.id=id;
        const mythis = this;
      await axios
        .post(process.env.VUE_APP_BASE_URL + `/user/v1/remove`,this.singleuser,config)
        .then((response) => {
          this.responseCode = response.data.statusCode;
          if (this.responseCode === "4000") {
            this.getUsers();
            this.alertMessage = response.data.msg;
            this.requestUpdate=true;
            setTimeout(function () {
                this.alertMessage ="";
            }, 1000);
            this.singleuser.id="";
          }
          if (this.responseCode === "4001") {
            this.alertMessage = response.data.msg;
            setTimeout(function () {
                this.alertMessage ="";
            }, 1000);
          }
        })
        .catch((error) => {
          if (error.response) {
            mythis.errorList = error.response.data.errors;
          } else if (error.request) {
            console.log(error.response.request);
          } else {
            console.error("Error:", error.message);
          }
        });
    },

  },
};
</script>
