<template>
    <div class="bg-gray-100 min-h-screen">
      <div class="container mx-auto py-6 flex flex-wrap">
        <div class="w-full md:w-1/2 md:pl-8">
          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">Bill ID:</p>
            <p class="whitespace-pre-line text-xl font-bold text-indigo-600">{{ this.$route.query.billId  }}</p>
          </div>
          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">ID:</p>
            <p class="whitespace-pre-line text-xl font-bold text-indigo-600">{{ product.id }}</p>
          </div>
          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">CI:</p>
            <p class="text-xl font-bold text-indigo-600">{{ product.ci }}</p>
          </div>
          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">User Service</p>
            <p class="text-xl font-bold text-indigo-600">Ksh {{ product.userService }}</p>
          </div>
          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">Payment Status:</p>
            <p class="text-xl font-bold text-indigo-600"> {{ product.paymentstatus }} </p> 
          </div> 
        </div>
        <div class="w-full md:w-1/2 md:pl-8">
            <div class="mb-4">
                <p class="text-lg font-semibold text-gray-700">Amount Paid:</p>
                <p class="text-xl font-bold text-indigo-600">{{ product.amount}}</p>
              </div>
            <div class="mb-4 ">
              <p class="text-lg font-semibold text-gray-700">Bill Cyle Start:</p>
              <p class="text-xl font-bold text-indigo-600">{{ product.start_bill }}</p>
            </div>
  
            <div class="mb-4 ">
              <p class="text-lg font-semibold text-gray-700">Next Bill Cyle:</p>
              <p class="text-xl font-bold text-indigo-600">{{ product.next_billing }}</p>
            </div>
  
            <div class="mb-4 ">
              <p class="text-lg font-semibold text-gray-700">Transaction Id:</p>
              <p class="text-xl font-bold text-indigo-600">{{ product.transactionid }}</p>
            </div>
          </div>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    data() {
        return {
            createsucess:"",
            error:"",
            isLoading:false,
            userId:"",
            file:null,
            responseCode:"",
            product: {
            id: "",
            ci: "",
            userService: "",
            paymentstatus: "",
            amount: "",
            start_bill: "",
            next_billing: "",
            transactionid:"",
            },
        }
    },
    mounted() {
        this.fetchProductData();
    },
    methods: {
        async  fetchProductData() {
      const productId = this.$route.query.billId;
      this.userId=this.hasUserId;
      try {
          const config = {
               headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           this.userProduct={
            id:productId
           }
           await axios.post(process.env.VUE_APP_BASE_URL+`/payment/v1/user-payment`,this.userProduct,config)
           .then(response => {
            this.isLoading=false;
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){
                 var products=response.data.data;
                 this.product={
                    id: products.id,
                    ci: products.ci,
                    userService: products.userService,
                    paymentstatus: products.paymentstatus,
                    amount: products.amount,
                    start_bill: products.start_bill,
                    next_billing: products.next_billing,
                    transactionid:products.transactionid,
                }
                this.createsucess="";
                this.userProduct={
                id:""
           }
               }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
            console.log(error);
           });  
        } catch (error) {
          console.error('API Error:', error);
        }

    },
      formatDateTime() {
   
      },
    },
    computed:{
      hasUserId(){
      return this.$store.state.authUser.userId;
    }
    }
  };
  </script>
  