<template>
    <div class="flex justify-center items-center">
      <div class="w-full sm:w-4/4">
        <div class="flex justify-center m-2">
          <input
            v-model="searchTerm"
            @input="filterProducts"
            placeholder="Search products..."
            class="p-2 m-3 rounded border border-gray-300"
          />
          <div class="flex m-2" v-if="hasUserId && hasUserRole === 'Admin'">
            <button
              @click="myProducts"
              class="bg-blue-500 hover:bg-green-700 text-white p-2 m-2 rounded focus:outline-none focus:shadow-outline"
            >
              My Products
            </button>
          </div>
        </div>
        <!-- Product list -->
        <div v-if="loading" class="text-center">Loading...</div>
        <div v-else>
          <div class="flex flex-wrap -mx-4">
            <div v-for="product in filteredProducts" :key="product.id" class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4">
              <div class="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-all mt-2 mb-3">
                <h3 class="text-xl font-bold mb-2">{{ product.productName }}</h3>
                <!-- Display product image if available -->
                <img
                  v-if="product.filename"
                  :src="getImageUrl(product.filename)"
                  alt="Product Image"
                  class="w-full h-32 object-cover mb-4 rounded"
                />
                <p class="text-gray-700 mb-2">Description: {{ product.productDesc }}</p>
                <div class="flex justify-between items-center">
                  <p class="text-gray-700">Price: Ksh {{ product.productPrice }}</p>
                  <div class="flex items-center">
                    <font-awesome-icon
                      icon="eye"
                      @click="viewProduct(product)"
                      class="cursor-pointer text-blue-500 mr-2"
                      style="font-size: 24px;"
                    />
                    <!-- Add other icons for update and delete -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        productUser:"",
        responseCode:"",
        products: [],
        loading: true,
        filteredProducts: [],
        searchTerm: '',
        
      };
    },
    mounted() {
      // Fetch the product data from the API when the component is mounted
      this.fetchProducts();
      
    },
    methods: {
      filterProducts() {
      this.filteredProducts = this.products.filter((product) => {
        // You can modify this condition based on the properties you want to search in
        return (
          product.productName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          product.productDesc.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      });
    },
      async fetchProducts() {
        try {
                 const config = {
                 headers: {
                 'Content-Type': 'application/json',
                 'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
                 'x-router-id': process.env.VUE_APP_ROUTER_ID,
                 'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
                 }
             };
             await axios.get(process.env.VUE_APP_BASE_URL+`/product/v1/products`,config)
             .then(response => {
                 this.responseCode=response.data.statusCode;
                 if(this.responseCode === "4000"){
                    this.isLoading=false;
                  this.products = response.data.data;
                  this.filteredProducts=response.data.data;
                 }else if(this.responseCode === "4001"){
                    this.error=response.data.msg;
                    this.isLoading=false;
                 }
             })
             .catch(error => {
                console.log(error);
                this.isLoading=false;
             });
          this.loading = false;
        } catch (error) {
          // Handle API error
          console.error('API Error:', error);
          this.loading = false;
        }
      },
      viewProduct(product){
        this.$router.push({ name: 'view-product', query: { productId: product._id } });
      },
  
      myProducts(){
        this.$router.push('/myproducts');
      }
    },
    computed:{
      hasUserId(){
      return this.$store.state.authUser.userId;
    },
    hasUserRole(){
        return this.$store.state.authUser.userRole;
    }
    }
  };
  </script>
  
  <style>
  /* Add your custom styles here */
  </style>
  