<template>
    <div class="bg-gray-100 min-h-screen">
      <div class="container mx-auto py-6 flex flex-wrap">
            <div class="w-full md:w-1/2 md:pl-8">
              <div class="mb-4">
                <p class="text-lg font-semibold text-gray-700">Customer ID:</p>
                <p class="whitespace-pre-line text-xl font-bold text-indigo-600">{{this.$route.query.customerId }} </p>
              </div>
              <div class="mb-4">
                <p class="text-lg font-semibold text-gray-700">Customer Name:</p>
                <p class="whitespace-pre-line text-xl font-bold text-indigo-600">{{ product.firstname }} {{ product.secondname  }}</p>
              </div>
              <div class="mb-4">
                <p class="text-lg font-semibold text-gray-700">Customer Service:</p>
                <p class="text-xl font-bold text-indigo-600"> {{ product.userService }} </p> 
              </div> 
              <div class="mb-4">
                <p class="text-lg font-semibold text-gray-700">Customer Mobile:</p>
                <p class="text-xl font-bold text-indigo-600">{{ product.mobile}}</p>
              </div>
            </div>
            <div class="w-full md:w-1/2 md:pl-8">
              <div class="mb-4">
                <p class="text-lg font-semibold text-gray-700">Creation Date:</p>
                <p class="text-xl font-bold text-indigo-600">{{ product.created_date}}</p>
              </div>
                <div class="mb-4 ">
                  <p class="text-lg font-semibold text-gray-700">Location:</p>
                  <p class="text-xl font-bold text-indigo-600">{{ product.location }}</p>
                </div>
      
                <div class="mb-4 ">
                  <p class="text-lg font-semibold text-gray-700">Customer Status:</p>
                  <p class="text-xl font-bold text-indigo-600">{{ product.status }}</p>
                </div>
      
                <div class="mb-4 ">
                  <p class="text-lg font-semibold text-gray-700">Configuration Item:</p>
                  <p class="text-xl font-bold text-indigo-600">{{ product.ci }}</p>
                </div>
              </div>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    data() {
        return {
            createsucess:"",
            error:"",
            isLoading:false,
            userId:"",
            file:null,
            responseCode:"",
            product: {
              firstname: "",
              secondname: "",
              userService: "",
              mobile: "",
              location: "",
              status: "",
              created_by: "",
              ci:"",
              created_date:"",
            },
            userProduct:{
                id:""
            },
        }
    },
    mounted() {
        this.fetchProductData();
    },
    methods: {
      viewAll(userInfo){
        this.$router.push({ name: 'user-services', query: { userId: userInfo.userId, name: userInfo.name } });
      },
      async  fetchProductData() {
      const productId = this.$route.query.customerId;
      try {
          const config = {
               headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           this.userProduct={
            id:productId
           }
           console.log(this.userProduct);
           await axios.post(process.env.VUE_APP_BASE_URL+`/expert/v1/user-product`,this.userProduct,config)
           .then(response => {
            this.isLoading=false;
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){
                var products=response.data.data;
                this.product={
                    firstname: products.firstname,
                    secondname: products.secondname,
                    userService: products.userService,
                    mobile: products.mobile,
                    location: products.location,
                    status: products.status,
                    created_by: products.created_by,
                    ci:products.ci,
                    created_date:products.created_date,
                }
                this.createsucess="";
                this.userProduct={
                id:""
           }
               }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
            console.log(error);
           });  
        } catch (error) {
          console.error('API Error:', error);
        }
    },
      formatDateTime() {
   
      },
    },
    computed:{
      hasUserId(){
      return this.$store.state.authUser.userId;
    }
    }
  };
  </script>
  