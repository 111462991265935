import { createStore } from "vuex";
const store = createStore({
    state(){
        return {
            isLoggedIn: false,
            authUser:{},
        }
    },
    mutations:{
        setIsLoggedIn(state, data){
            state.isLoggedIn = data;
        },
        setAuthUser(state, data){
            state.authUser=data;
        }
}});

export default store;