<template>
    <div class="flex justify-center items-center full-screen">
      <div class="w-full sm:w-3/4">
        <h2 class="text-2xl font-bold mb-4 text-center">Create Customer</h2>
        <div>
          <div v-if="this.createsucess.length>0">
              <div class="alert alert-success" role="alert">
                  {{ this.createsucess }}
              </div>
          </div>
          <div class="bg-red-500 text-white font-bold rounded-t px-2 py-2" v-if="this.error.length>0" >
              <div class="" role="alert">
                  {{ this.error }}
              </div>
            </div>
      </div>
      
        <form @submit.prevent="submitForm" class="bg-green-300 shadow-md rounded px-8 pb-3 mt-2 mb-4">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div class="mb-2">
              <label for="firstname" class="block text-gray-700 text-sm font-bold mb-2">First Name:</label>
              <input type="text" id="firstname" v-model="product.firstname" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
            </div>
            <div class="mb-2">
              <label for="secondname" class="block text-gray-700 text-sm font-bold mb-2">Second Name:</label>
              <input type="text" id="secondname" v-model="product.secondname" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
            </div>
            <div class="mb-2">
              <label for="category" class="block text-gray-700 text-sm font-bold mb-2">Customer Service:</label>
              <select v-model="product.userService" id="category" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required>
                <option value="" disabled selected>Select a category</option>
                <option v-for="userService in internetCategories" :value="userService" :key="userService">{{ userService }}</option>
              </select>
            </div>
            <div class="mb-2">
              <label for="mobile" class="block text-gray-700 text-sm font-bold mb-2">Customer Mobile:</label>
              <input type="text" id="mobile" v-model="product.mobile" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
            </div>
            <div class="mb-2">
              <label for="location" class="block text-gray-700 text-sm font-bold mb-2">Location:</label>
              <input type="text" id="location" v-model="product.location" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
            <div class="mb-2">
              <label for="ci" class="block text-gray-700 text-sm font-bold mb-2">Customer Configuration Item/Link:</label>
              <input type="text" id="ci" v-model="product.ci" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
            <div class="mb-2">
              <label for="status" class="block text-gray-700 text-sm font-bold mb-2">Customer Status:</label>
              <select v-model="product.status" id="status" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required>
                <option value="" disabled selected>Select a category</option>
                <option v-for="status in accountStatus" :value="status" :key="status">{{ status }}</option>
              </select>
            </div>
            <!-- <div class="mb-2">
              <label for="file" class="block text-gray-700 text-sm font-bold mb-2">Product Image:</label>
              <input type="file" id="file" ref="fileInput" @change="onFileChange" class="w-full" />
            </div> -->
            <div class="flex justify-center mt-2">
              <button type="submit" class="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <span v-if="!isLoading">Create Expertise</span>
                <span v-else class="spinner-border text-dark"></span>
              </button>
            </div>
          </div>
          
        </form>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        createsucess:"",
        error:"",
        isLoading:false,
        userId:"",
        file:null,
        product: {
  firstname: "",
  secondname: "",
  userService: "",
  mobile: "",
  location: "",
  status: "",
  created_by: "",
  ci:"",
},
      // Categories data array for internet Packages in Vue.js
        internetCategories :[
        '3 Mbps',
        '5 Mbps',
        '8 Mbps',
        '10 Mbps',
        '15 Mbps',
        '20 Mbps',
        // Add more categories as needed
        ],
        // data array for account status in Vue.js
        accountStatus :[
        '0',
        '1',
        '2',
        '3',
        // Add more categories as needed
        ],


      };
    },
    methods: {
      onFileChange(event) {
      this.file = event.target.files[0];
    },
      async submitForm() {
        this.isLoading=true;
        this.userId=this.hasUserId;
        const formData = new FormData();
        formData.append('firstname', this.product.firstname);
        formData.append('secondname', this.product.secondname);
        formData.append('location', this.product.location);
        formData.append('userService', this.product.userService);
        formData.append('mobile', this.product.mobile);
        formData.append('status', this.product.status);  
        formData.append('created_by', this.userId);  
        formData.append('ci', this.product.ci);   
        try {
          const config = {
               headers: {
               'Content-Type': 'multipart/form-data',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           await axios.post(process.env.VUE_APP_BASE_URL+`/expert/v1/add-product`,formData,config)
           .then(response => {
            this.isLoading=false;
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){
                this.createsucess=response.data.msg;
                  this.product = {
                    firstname: "",
                    secondname: "",
                    userService: "",
                    mobile: "",
                    location: "",
                    status: "",
                    created_by: "",
                    ci:"",
                    },
                // this.file=null;
                // this.$refs.fileInput.value = null;
                this.$router.push('/services');
               }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
              console.log(error); 
           });  
        } catch (error) {
          console.error('API Error:', error);
        }
      }
      
    },
    computed:{
      hasUserId(){
      return this.$store.state.authUser.userId;
    }
    }
  };
  </script>
  
  <style>
  /* Add your custom styles here */
  </style>
  