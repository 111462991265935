<template>
<div class="flex flex-col min-h-screen">
  <div class="flex-grow">
    <AppHeader v-if="isLoggedIn"/>
    <router-view>
    </router-view>
  </div>
  <FooterView/>
</div>
</template>
<script>
import AppHeader from './components/AppHeader.vue';
import FooterView from './components/FooterView.vue';
export default {
  props:['user'],
  name: 'App',
  components: {
    AppHeader,
    FooterView
  },
  data(){
    return{
      loggedInUser: "",
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  mounted() {
       this.loggedInUser=this.$store.state.authUser.user
        if (this.loggedInUser) {
          this.$store.commit('setIsLoggedIn', true);
          this.$store.commit('setAuthUser',{"user":this.loggedInUser});
        } else {
          this.$store.commit('setIsLoggedIn', false);
          this.$store.commit('setAuthUser',{});
        }
      },
}
</script>
<style>
html,
body {
  /* Make sure the body fills the viewport height */
  height: 100%;
}

#app {
  /* Use Flexbox to create a column layout for the app */
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the app takes at least the full viewport height */
}

/* The footer will be placed at the bottom, but may overlap content if content fills the page */
footer {
  position: sticky;
  bottom: 0; /* Push the footer to the bottom of the container */
}

</style>