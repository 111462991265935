<template>
    <div class="flex justify-center h-screen">
      <div class="w-full sm:w-3/4">
        <h5 class="text-2xl font-bold mb-4 text-center">Edit Customer Details</h5>
        <div>
          <div v-if="this.createsucess.length>0">
              <div class="alert alert-success" role="alert">
                  {{ this.createsucess }}
              </div>
          </div>
          <div class="bg-red-500 text-white font-bold rounded-t px-2 py-2" v-if="this.error.length>0" >
              <div class="" role="alert">
                  {{ this.error }}
              </div>
            </div>
      </div>
        <form @submit.prevent="updateProduct" class="bg-green-300 shadow-md rounded px-8 pb-8 mb-4 grid grid-cols-2 gap-4">
          <div class="mb-4">
            <label for="firstname" class="block text-gray-700 text-sm font-bold mb-2">FirstName:</label>
            <input type="text" id="firstname" v-model="product.firstname" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
          </div>
          <div class="mb-4">
            <label for="secondname" class="block text-gray-700 text-sm font-bold mb-2">SecondName:</label>
            <input type="text" id="secondname" v-model="product.secondname" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
          </div>
          <div class="mb-4">
            <label for="category" class="block text-gray-700 text-sm font-bold mb-2">Customer Service:</label>
              <select v-model="product.userService" id="category" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required>
                <option value="" disabled selected>Select a category</option>
                <option v-for="userService in internetCategories" :value="userService" :key="userService">{{ userService }}</option>
              </select>
          </div>
          <div class="mb-4">
            <label for="mobile" class="block text-gray-700 text-sm font-bold mb-2">Customer Mobile:</label>
            <input type="text" id="mobile" v-model="product.mobile" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
          </div>
          <div class="mb-4">
            <label for="location" class="block text-gray-700 text-sm font-bold mb-2">Location:</label>
            <input type="text" id="location" v-model="product.location" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div class="mb-4">
            <label for="ci" class="block text-gray-700 text-sm font-bold mb-2">Customer Configuration Item/Link:</label>
            <input type="text" id="ci" v-model="product.ci" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div class="mb-4">
            <label for="status" class="block text-gray-700 text-sm font-bold mb-2">Customer Status:</label>
            <select v-model="product.status" id="status" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required>
              <option value="" disabled selected>Select a category</option>
              <option v-for="status in accountStatus" :value="status" :key="status">{{ status }}</option>
            </select>
          </div>
          <div class="flex justify-center mt-4">
            <button type="submit" class="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              <span v-if="!isLoading">Update Customer Details</span>
              <span v-else class="spinner-border text-dark"></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        createsucess:"",
        error:"",
        isLoading:false,
        userId:"",
        file:null,
        product: {
          firstname: "",
          secondname: "",
          userService: "",
          mobile: "",
          location: "",
          status: "",
          created_by: "",
          ci:"",
        },
      // Categories data array for internet Packages in Vue.js
        internetCategories :[
        '3 Mbps',
        '5 Mbps',
        '8 Mbps',
        '10 Mbps',
        '15 Mbps',
        '20 Mbps',
        // Add more categories as needed
        ],
        // data array for account status in Vue.js
        accountStatus :[
        '0',
        '1',
        '2',
        '3',
        // Add more categories as needed
        ],
        userProduct:{
            id:""
           },
      };
    },
    created() {
    // Fetch the product data using the productId from route params
    this.fetchProductData();
  },
  mounted() {
      // Fetch the product data from the API when the component is mounted
      this.fetchProductData();
    },
    methods: {
      onFileChange(event) {
      this.file = event.target.files[0];
    },
      async updateProduct() {
        this.isLoading=true;
        this.userId=this.hasUserId;
        const formData = new FormData();
        formData.append('firstname', this.product.firstname);
        formData.append('secondname', this.product.secondname);
        formData.append('location', this.product.location);
        formData.append('userService', this.product.userService);
        formData.append('mobile', this.product.mobile);
        formData.append('status', this.product.status);  
        formData.append('updated_by', this.userId);  
        formData.append('ci', this.product.ci);  
        formData.append('_id', this.$route.query.customerId);  
  
        try {
          const config = {
               headers: {
               'Content-Type': 'multipart/form-data',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };

           await axios.post(process.env.VUE_APP_BASE_URL+`/expert/v1/update`,formData,config)
           .then(response => {
            this.isLoading=false;
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){
                this.createsucess=response.data.msg;
                this.product = {
                    firstname: "",
                    secondname: "",
                    userService: "",
                    mobile: "",
                    location: "",
                    status: "",
                    created_by: "",
                    ci:"",
                  },
                this.$router.push('/services');
               }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
              console.log(error); 
           });  
        } catch (error) {
          console.error('API Error:', error);
        }
      },
      async  fetchProductData() {
      const productId = this.$route.query.customerId;
      try {
          const config = {
               headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           this.userProduct={
            id:productId
           }
           console.log(this.userProduct);
           await axios.post(process.env.VUE_APP_BASE_URL+`/expert/v1/user-product`,this.userProduct,config)
           .then(response => {
            this.isLoading=false;
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){
                var products=response.data.data;
                console.log(this.product);
                this.product={
                    firstname: products.firstname,
                    secondname: products.secondname,
                    id:products.id,
                    userService: products.userService,
                    mobile: products.mobile,
                    location: products.location,
                    status: products.status,
                    created_by: products.created_by,
                    ci:products.ci,
                }
                this.createsucess="";
                this.userProduct={
                id:""
           }
               }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
            console.log(error);
           });  
        } catch (error) {
          console.error('API Error:', error);
        }
    },
      
    },
   
    computed:{
      hasUserId(){
      return this.$store.state.authUser.userId;
    }
    }
  };
  </script>
  
  <style>
  /* Add your custom styles here */
  </style>
  