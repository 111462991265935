<template>
  <nav class="navbar navbar-expand-lg navbar-expand-sm navcont">
    <div class="flex justify-between items-center w-full">
      <div class="flex items-center">
        <button
          class="block lg:hidden text-white px-3 py-2 focus:outline-none"
          @click="toggleMenu"
        ><font-awesome-icon icon="bars" />
        </button>
        <ul
          :class="{
            'hidden': !isMenuOpen,
            'flex': isMenuOpen,
            'lg:flex': true
          }"
          class="navbar-nav mb-2 mb-lg-0 lg:space-x-4">
          <li class="nav-item" v-for="item in list" :key="item.to">
            <router-link class="nav-link text-white" :to="item.to">{{ item.title }}</router-link>
          </li>
          <li class="nav-item" v-if="isLoggedIn">
            <a type="button" class="nav-link text-white" @click="logout">Logout</a>
          </li>
        </ul>
      </div>

      <!-- Display the username at the end of the nav bar -->
      <span @click="userProfile" class="navbar-text text-white mr-4">
        {{ hasUsername }}
      </span>
    </div>
  </nav>
</template>
  
  <script>
  export default {
    data() {
    return {
      username:this.$store.state.authUser,
      isMenuOpen: false,
      list: [
        { title: "Home", to: "/" },
        { title: "Products", to: "/myproducts" },
        { title: "Customers", to: "/services" },
        { title: "Payments", to: "/payments" },
        { title: "Notification", to: "/notifications" },
      ],
     
    }
  },
  methods: {
    logout(){
      this.$store.commit('setIsLoggedIn',false);
      this.$store.commit( 'authUser',null);
      this.$router.push('/login');
    },
    openLogin(){
      
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    userProfile(){
      this.$router.push('profile');
    }

   
  },
  computed: {
    isLoggedIn(){
      return this.$store.state.isLoggedIn;
    },
    hasUsername(){
      return this.$store.state.authUser.user;
    }

  },
   
  
  }
  </script>
  <style>
  .navcont {
    background-color: darkblue;
    background-size: cover;
    background-position: center;
  }
  </style>