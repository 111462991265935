<template>
  <div class="bg-gray-100 min-h-screen">
    <div class="container mx-auto py-6 flex flex-wrap">
      <!-- Image on the Left -->
      <div class="w-full md:w-1/3 mb-6 md:mb-0 flex items-center justify-center">
        <img
          v-if="!loading && user && user.filename"
          :src="getImageUrl(user.filename)"
          alt="User Avatar"
          class="w-80 h-80 object-cover rounded"
        />
        <div v-if="loading" class="text-center">Loading...</div>
        <div v-if="!loading && (!user || !user.filename)" class="text-center text-red-500">Error loading avatar</div>
      </div>

      <!-- User Details on the Right -->
      <div class="w-full md:w-1/3 md:pl-8">
        <h2 class="text-3xl font-bold mb-4" v-if="!loading">{{ user.firstname }} {{ user.secondname }}</h2>
        <div v-if="!loading && user">
          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">Username:</p>
            <p>{{ user.username }}</p>
          </div>

          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">Role:</p>
            <p>{{ user.role }}</p>
          </div>

          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">Mobile:</p>
            <p>{{ user.mobile }}</p>
          </div>

          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">Category:</p>
            <p>{{ user.category }}</p>
          </div>

          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">Sell Option:</p>
            <p>{{ user.selloption }}</p>
          </div>

          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">Location:</p>
            <p>{{ user.location }}</p>
          </div>
          <button class="bg-blue-500 text-white px-4 py-2 rounded mt-4" @click="toggleUpdateForm">Update Details</button>

          <div v-if="showUpdateForm" class="mt-4">
            <!-- Form fields to update user details -->
            <p class="text-lg font-semibold text-gray-700">Update Form:</p>
            <form @submit.prevent="updateUser" class="flex flex-wrap">
              <!-- Right-align the form on larger screens (md and above) -->
              <div class="w-full md:w-1/2 pr-4">
                <div class="mb-4">
                  <label class="block text-gray-700 font-bold mb-2" for="firstname">First Name:</label>
                  <input v-model="updatedUser.firstname" type="text" id="firstname" class="w-full px-3 py-2 border rounded">
                </div>
    
                <div class="mb-4">
                  <label class="block text-gray-700 font-bold mb-2" for="secondname">Second Name:</label>
                  <input v-model="updatedUser.secondname" type="text" id="secondname" class="w-full px-3 py-2 border rounded">
                </div>
    
                <div class="mb-4">
                  <label class="block text-gray-700 font-bold mb-2" for="username">Username:</label>
                  <input v-model="updatedUser.username" type="text" id="username" class="w-full px-3 py-2 border rounded">
                </div>
    
                <!-- Add more form fields for other user details (password, mobile, category, selloption, location, role, etc.) -->
              </div>
              
              <div class="w-full md:w-1/2">
                <!-- Add the rest of the form fields here on the right side -->
              </div>
    
              <!-- Buttons for update and close -->
              <div class="w-full mt-4">
                <button class="bg-green-500 text-white px-4 py-2 rounded" type="submit">Update</button>
                <button class="bg-red-500 text-white px-4 py-2 rounded ml-2" @click="closeUpdateForm" type="button">Close</button>
              </div>
            </form>
          </div>
        </div>
        <div v-if="!loading && !user">
          <div class="text-center text-red-500">Error loading user data</div>
        </div>
      </div>
       <!-- Update Form -->
      
    </div>
  </div>
</template>

  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        loading:true,
        errors:"",
        showUpdateForm: false,
        responseCode:"",
        userDetails:[],
        user: {
          firstname: '',
          secondname: '',
          username: '',
          mobile: '',
          category: '',
          selloption: '',
          location: '',
          role: '',
          filename:''
        },
        updatedUser: {
          firstname: '',
          secondname: '',
          username: '',
          mobile: '',
          category: '',
          selloption: '',
          location: '',
          role: '',
          filename:''
      },
        userRequest:{
          username:""
        }
      };
    },
    mounted() {
      this.getUser();
    },
    methods: {
      toggleUpdateForm() {
      this.showUpdateForm = true;
    },
    closeUpdateForm() {
      this.showUpdateForm = false;
    },
      getImageUrl(filename) {
        return `http://localhost:5050/experts/${filename}`; // The "images" path corresponds to the path on the backend where your images are served.
      },
      async getUser(){
        this.loading=true;
      this.userRequest={
        username:this.hasUsername,
      }
      console.log(this.userRequest);
      const config = {
               headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           await axios.post(process.env.VUE_APP_BASE_URL+`/user/v1/user`,this.userRequest,config)
           .then(response => {
               this.responseCode=response.data.statusCode;
               this.userDetails=response.data.data;
               if(this.responseCode === "4000"){
                console.log(this.userDetails);
                this.user={
                  firstname: this.userDetails.firstname,
                  secondname: this.userDetails.secondname,
                  username: this.userDetails.username,
                  mobile: this.userDetails.mobile,
                  category: this.userDetails.category,
                  selloption: this.userDetails.selloption,
                  location: this.userDetails.location,
                  role: this.userDetails.role,
                  filename:this.userDetails.filename
                };
                this.updatedUser=this.userDetails;
                this.errors="";
                this.loading=false;
               }else if(this.responseCode === "4001"){
                this.loading=false;
                this.errors=response.data.msg;
               }
           })
           .catch(error => {
              console.log(error);
              
           });
          }

      
    },

    computed:{
      hasUsername(){
      return this.$store.state.authUser.user;
    }
    },
  };
  </script>
  
  <style>
  /* Add Tailwind CSS here if not already included */
  
  .user-profile-card {
    max-width: 400px;
  }
  
  /* Add responsive styles using Flexbox */
  @media (min-width: 640px) {
    .user-profile-card {
      max-width: 500px;
    }
  }
  
  @media (min-width: 768px) {
    .user-profile-card {
      max-width: 600px;
    }
  }
  
  @media (min-width: 1024px) {
    .user-profile-card {
      max-width: 800px;
    }
  }
  
  @media (min-width: 1280px) {
    .user-profile-card {
      max-width: 1000px;
    }
  }
  
  /* Center the content */
  .profile-info {
    flex: 1;
  }
  </style>
  