<template>
    <div class="flex justify-center items-center">
      <div class="w-full sm:w-4/4">
        <div class="flex justify-center m-1">
          <input
            v-model="searchTerm"
            @input="filterProducts"
            placeholder="Search products..."
            class="p-2 rounded border border-gray-300"
          />
        </div>
        <div class="flex justify-start p-2">
            {{ name }} products
          </div>
        <!-- Product list -->
        <div v-if="loading" class="text-center">Loading...</div>
        <div v-else>
          <div class="flex flex-wrap -mx-2">
            <div v-for="product in filteredProducts" :key="product.id" class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4">
              <div class="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-all mt-2 mb-3">
                <h3 class="text-xl font-bold mb-2">{{ product.productName }}</h3>
                <!-- Display product image if available -->  
                <img v-if="product.filename" :src="getImageUrl(product.filename)" alt="Product Image" class="w-full h-32 object-cover mb-4" />
                <p class="text-gray-700 mb-2">Description: {{ product.productDesc }}</p>
                <div class="inline-flex">
                  <p class="text-gray-700">Price: Ksh {{ product.productPrice }}</p>
                  <p class="text-gray-700">Category: {{ product.category }}</p>
                  <p class="text-gray-700">Location: {{ product.location }}</p>
                </div>
                <!-- Icons for View, Update, and Delete -->
                <div class="flex justify-end mt-4">
                  <div class="flex justify-between mt-4">
                    <font-awesome-icon icon="eye" @click="viewProduct(product)" class="cursor-pointer text-blue-500 mr-2"  style="font-size: 24px;"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        productUser:"",
        responseCode:"",
        products: [],
        loading: true,
        filteredProducts: [],
        searchTerm: '',
        name:"",
        userProduct:{
                  userId:""
              },    
      };
    },
    mounted() {
      // Fetch the product data from the API when the component is mounted
      this.fetchProducts();
      this.name=this.$route.query.name;
    },
    
    methods: {
      filterProducts() {
        this.filteredProducts = this.products.filter((product) => {
          // You can modify this condition based on the properties you want to search in
          return (
            product.productName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            product.productDesc.toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        });
      },
      async fetchProducts() {
        try {
                 this.userId=this.$route.query.userId;
                 console.log(this.userId);
                 const config = {
                 headers: {
                 'Content-Type': 'application/json',
                 'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
                 'x-router-id': process.env.VUE_APP_ROUTER_ID,
                 'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
                 }
             };
             this.userProduct={
              userId:this.userId
             };
             await axios.post(process.env.VUE_APP_BASE_URL+`/product/v1/user-products`,this.userProduct,config)
             .then(response => {
                 this.responseCode=response.data.statusCode;
                 if(this.responseCode === "4000"){
                  this.products = response.data.data;
                  this.filteredProducts=response.data.data;
                  this.userProduct={
                   userId:""
                  };
                  this.isLoading=false;
                 }else if(this.responseCode === "4001"){
                    this.error=response.data.msg;
                    this.isLoading=false;
                 }
             })
             .catch(error => {
                console.log(error);
                
             });
        
          this.loading = false;
        } catch (error) {
          console.error('API Error:', error);
          this.loading = false;
        }
      },
      viewProduct(product){
        this.$router.push({ name: 'view-product', query: { productId: product._id } });
      },
    },
   
  };
  </script>
  
  <style>
  /* Add your custom styles here */
  </style>
  