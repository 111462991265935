<template>
    <div class="flex justify-center items-center h-screen bggreen">
      <div class="w-full sm:w-2/4">
        
        <form @submit.prevent="submitForm" class="bg-green-300 shadow-md rounded px-8 pt-3 pb-3 mb-5">
          <h2 class="text-2xl font-bold mb-1ßß text-center">Create Account</h2>
        <div>
            <div v-if="this.loginsucess.length>0">
                <div class="alert alert-success" role="alert">
                    {{ this.loginsucess }}
                </div>
            </div>
            <div class="bg-red-500 text-white font-bold rounded-t px-2 py-2" v-if="this.error.length>0" >
                <div class="" role="alert">
                    {{ this.error }}
                </div>
              </div>
        </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label for="firstname" class="block text-gray-700 text-sm font-bold mb-1">First Name:</label>
              <input type="text" id="firstname" v-model="account.firstname" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
            <div>
              <label for="secondname" class="block text-gray-700 text-sm font-bold mb-1">Second Name:</label>
              <input type="text" id="secondname" v-model="account.secondname" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
            <div>
              <label for="username" class="block text-gray-700 text-sm font-bold mb-1">Username:</label>
              <input type="text" id="username" v-model="account.username" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
            <div>
              <label for="mobile" class="block text-gray-700 text-sm font-bold mb-1">Mobile:</label>
              <input type="text" id="mobile" v-model="account.mobile" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
            <div>
              <label for="role" class="block text-gray-700 text-sm font-bold mb-1">Role:</label>
              <select id="role" v-model="account.role" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                <option value="" disabled>Select a role</option>
                <option value="Farmer">Farmer</option>
                <option value="Service provider/Manufacturer">Service provider/Manufacturer</option>
                <option value="Consumer">Consumer</option>
              </select>
            </div>
            <div>
              <label for="password" class="block text-gray-700 text-sm font-bold mb-1">Password:</label>
              <input type="password" id="password" v-model="account.password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
          </div>
          <div class="flex justify-center mt-2">
            <button type="submit" class="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <span v-if="!isLoading">Create Account</span>
                <span v-else class="spinner-border text-dark"></span>
               </button>
          </div>
          
        </form>
        <div class="flex justify-center mt-1">
          <p class="text-white">Already have an account? <button @click="goToLogin" class="text-blue-500 hover:text-blue-700">Go to Login</button></p>
        </div>
       
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        loginsucess:"",
        isLoading: false,
        error:"",
        responseCode:"",
        account: {
          firstname: "",
          secondname: "",
          username: "",
          mobile: "",
          role: "",
          password: ""
        }
      };
    },
    methods: {
      async submitForm() {
        this.isLoading = true;
               const config = {
               headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           await axios.post(process.env.VUE_APP_BASE_URL+`/user/v1/create-user`,this.account,config)
           .then(response => {
            console.log(response);
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){
                    this.loginsucess=response.data.msg;
                    this.isLoading = false;
                    this.account = {
                    firstname: "",
                    secondname: "",
                    username: "",
                    mobile: "",
                    role: "",
                    password: ""
                    };
                    this.$router.push('/login');
               }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
              console.log(error);
              
           });
        
    
      },
      goToLogin() {
        this.$router.push('/login');
      }
    }
  };
  </script>
  
  <style>
  .bggreen {
    background-image: url('../../../src/assets/123456.jpeg'); /* Replace '/path/to/green-farm.jpg' with the actual path to your image */
    background-size: cover;
    background-position: 100%;
  }
  </style>
  