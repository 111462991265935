<template>
    <div class="bg-gray-100 min-h-screen">
      <div class="container mx-auto py-6 flex flex-wrap">
        <!-- Product Details on the Right -->
        <div class="w-full md:w-1/2 md:pl-8">
          <h2 class="text-3xl font-bold mb-4">{{ product.productName }}</h2>
  
          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">Description:</p>
            <p class="whitespace-pre-line">{{ product.productDesc }}</p>
          </div>
          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">Price:</p>
            <p class="text-xl font-bold text-indigo-600">Ksh {{ product.productPrice }}</p>
          </div>
          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">Name:</p>
            <p > {{ userInfo.name }} </p> 
          </div>
  
          <div class="mb-4">
            <p class="text-lg font-semibold text-gray-700">Mobile Number:</p>
            <p>{{ userInfo.mobile }}</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 md:pl-8">
            <div class="mb-4  md:w-1/3 md:pr-4">
              <p class="text-lg font-semibold text-gray-700">Location:</p>
              <p>{{ product.location }}</p>
            </div>
  
            <div class="mb-4 md:w-1/3 md:pr-4">
              <p class="text-lg font-semibold text-gray-700">Category:</p>
              <p>{{ product.category }}</p>
            </div>
  
            <div class="mb-4 md:w-1/3">
              <p class="text-lg font-semibold text-gray-700">Created Date:</p>
              <p>{{ product.dateCreate }}</p>
            </div>
          </div>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    data() {
        return {
            createsucess:"",
            error:"",
            isLoading:false,
            userId:"",
            file:null,
            responseCode:"",
            product: {
            productName: "",
            productDesc: "",
            productPrice: "",
            location: "",
            category: "",
            filename:"",
            dateCreate:""
            },
            userInfo:{
            name:"",
            mobile:"",
            userId:"",
            },
            userProduct:{
                userId:"",
                id:""
            },
        }
    },
    mounted() {
        this.fetchProductData();
    },
    methods: {
      viewAll(userInfo){
        this.$router.push({ name: 'user-products', query: { userId: userInfo.userId, name: userInfo.name } });
      },
        async  fetchProductData() {
      const productId = this.$route.query.productId;
      this.userId=this.hasUserId;
      try {
          const config = {
               headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           this.userProduct={
            userId:this.userId,
            id:productId
           };
           await axios.post(process.env.VUE_APP_BASE_URL+`/product/v1/user-product-details`,this.userProduct,config)
           .then(response => {
            this.isLoading=false;
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){
                this.product=response.data.data;
                this.userInfo=response.data.userInfo;
                this.product={
                    productName:this.product[0].productName,
                    productDesc: this.product[0].productDesc,
                    productPrice: this.product[0].productPrice,
                    location: this.product[0].location,
                    category: this.product[0].category,
                    filename:this.product[0].filename,
                    dateCreate:this.product[0].created_date,
                };
                this.userInfo={
                    name:this.userInfo[0].firstname +" "+ this.userInfo[0].secondname,
                    mobile:this.userInfo[0].mobile,
                    userId:this.userInfo[0]._id
                };
                this.createsucess="";
                this.userProduct={
                userId:"",
                id:""
           }
               }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
            console.log(error);
           });  
        } catch (error) {
            console.log(error);
          console.error('API Error:', error);
        }

    },
      formatDateTime() {
   
      },
    },
    computed:{
      hasUserId(){
      return this.$store.state.authUser.userId;
    }
    }
  };
  </script>
  