<template>
    <div class="cont flex justify-center items-center h-screen">
      <div class="w-full sm:w-2/4">
        <h2 class="text-2xl font-bold mb-4">Login Fibre Manager</h2>
        <form @submit.prevent="submit" class="bg-green-300 shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div class="mb-4">
            <label for="username" class="block text-gray-700 text-sm font-bold mb-2">Username:</label>
            <input type="text" id="username" v-model="model.member.username" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div class="mb-4">
            <label for="password" class="block text-gray-700 text-sm font-bold mb-2">Password:</label>
            <input type="password" id="password" v-model="model.member.password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
            <!-- <button @click="goToRegister" class="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Create Account</button> -->
            <button type="submit" class="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Login</button>
            
          </div>
        </form>
      </div>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  export default {
     components: {},
     data() {
         return {
          loginsucess:"",
          user:"",
          userId:"",
          userdata:{},
          userRole:"",
          isLoading: false,
          responseCode:"",
          model:{
                  member:{
                  username:"",
                  password:""
                  }
              }
         }
     },
     mounted() {
        // this.$refs.usernameRef.focus();
     },
     computed: {
         isLoginOpen(){
             return this.$store.state.isLoginOpen;
         }
     },
     methods: {
        goToRegister() {
        this.$router.push('/account');
      },
     async submit(){
              this.isLoading = true;
               const config = {
               headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           await axios.post(process.env.VUE_APP_BASE_URL+`/user/v1/login`,this.model.member,config)
           .then(response => {
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){

                  this.loginsucess=response.data.msg;
                  this.userdata=response.data.body;
                  this.user=this.userdata[0].username;
                  this.userId=this.userdata[2].id;
                  this.userRole=this.userdata[1].role;

                  this.model.member={
                      username:"",
                      password:""
                   };
                   this.isLoading = false;
                   this.$store.commit('setIsLoggedIn',true);
                   this.$store.commit('setAuthUser',{"user":this.user,"userId":this.userId,"userRole":this.userRole});
                   this.loginsucess="";
                   const redirect = this.$route.query.redirect || '/';
                   this.$router.push(redirect);
                 
               }else if(this.responseCode === "4001"){
                  this.loginsucess=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
              console.log(error);
              
           });
              
          },
         close(){
             this.$store.commit('setLoginModal',false);
         },
         
     },
  }
  </script>
  
  <style scoped>
.cont{
  background-image: url('../../../src/assets/123456.jpeg'); /* Replace '/path/to/green-farm.jpg' with the actual path to your image */
  background-size: cover;
  background-position: center;
}
</style>
  