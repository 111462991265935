<template>
    <div class="flex justify-center items-center full-screen">
      <div class="w-full sm:w-3/4">
        <h2 class="text-2xl font-bold mb-4 text-center">Send customer notifications</h2>
        <div>
          <div v-if="this.createsucess.length>0">
              <div class="alert alert-success" role="alert">
                  {{ this.createsucess }}
              </div>
          </div>
          <div class="bg-red-500 text-white font-bold rounded-t px-2 py-2" v-if="this.error.length>0" >
              <div class="" role="alert">
                  {{ this.error }}
              </div>
            </div>
      </div>
        <form @submit.prevent="submitForm" class="bg-green-300 shadow-md rounded px-8 pb-3 mt-2 mb-4">
          <div class="grid grid-cols-1 md:grid-cols-1 gap-3">
            <div class="mb-2">
                <label for="paymentstatus" class="block text-gray-700 text-sm font-bold mb-2">Messaget Category:</label>
                <select v-model="requestbody.to" id="status" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required>
                  <option value="" disabled selected>Select a category</option>
                  <option v-for="to in accountStatus" :value="to" :key="to">{{ to }}</option>
                </select>
              </div>
              <div v-if="requestbody.to=='one'">
                <div class="mb-2">
                  <label for="paymentstatus" class="block text-gray-700 text-sm font-bold mb-2">Select Mobile Number:</label>
                    <select v-model="cat" id="to" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required>
                      <option value="" disabled selected>Select Mobile Number:</option>
                      <option v-for="cat in msisdns" :value="cat" :key="cat">{{ cat }}</option>
                    </select>
                  </div>
            </div>
            <div v-else>
              <div class="mb-2">
                <label for="paymentstatus" class="block text-gray-700 text-sm font-bold mb-2">Mobile Numbers:</label>
                <input type="text" id="msisdns" v-model="all" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                </div>
          </div>
          
            <div class="mb-2">
                <label for="productDesc" class="block text-gray-700 text-sm font-bold mb-2">Message:</label>
                <textarea id="productDesc" v-model="requestbody.message" rows="4" placeholder="Enter message here....." class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required></textarea>
              </div>
            <div class="flex justify-center mt-2">
              <button type="submit" class="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <span v-if="!isLoading">Send Notification</span>
                <span v-else class="spinner-border text-dark"></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        createsucess:"",
        error:"",
        isLoading:false,
        userId:"",
        products:{},
        requestbody:{
            to:"",
            message:""
        },
        numbers:[],
        cat:"",
            accountStatus :[
        'all',
        'one'
        ],
        all:"all customer numbers",
        msisdns:[],
      };
    },
    mounted() {
    this.fetchProducts();
    
  },
    methods: {
        async fetchProducts() {
      try {
               const config = {
               headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           await axios.get(process.env.VUE_APP_BASE_URL+`/expert/v1/products`,config)
           .then(response => {
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){
                this.isLoading=false;
                this.products = response.data.data;
                console.log(this.products);
                for (let i = 0; i < this.products.length; i++) {
                 this.msisdns.push(this.products[i].mobile);
                }
               }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
              console.log(error);
              this.isLoading=false;
           });
        this.loading = false;
      } catch (error) {
        // Handle API error
        console.error('API Error:', error);
        this.loading = false;
      }
    },
      async submitForm() {
        this.isLoading=true;
        this.userId=this.hasUserId;
        var data=[];
        const formData = new FormData();
        if (this.requestbody.to == "all") {
          for (let i = 0; i < this.msisdns.length; i++) {
          data.push(this.msisdns[i]);
          }
        }else{
        data.push(this.cat);
        }
        formData.append('to', JSON.stringify(data));
        formData.append('message', this.requestbody.message);
        try {
          const config = {
               headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           await axios.post(process.env.VUE_APP_BASE_URL+`/send/v1/send-sms`,formData,config)
           .then(response => {
               this.isLoading=false;
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){
                this.createsucess=response.data.msg;
                  this.requestbody={
                        to:"",
                        message:""
                    };
            }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
              console.log(error); 
           });  
        } catch (error) {
          console.error('API Error:', error);
        }
      },    
    },
    computed:{
      hasUserId(){
      return this.$store.state.authUser.userId;
    }
    }
  };
  </script>
  
  <style>
  /* Add your custom styles here */
  </style>
  