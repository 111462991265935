<template>
    <div class="flex justify-center items-center full-screen">
      <div class="w-full sm:w-3/4">
        <h2 class="text-2xl font-bold mb-4 text-center">Create Payment</h2>
        <div>
          <div v-if="this.createsucess.length>0">
              <div class="alert alert-success" role="alert">
                  {{ this.createsucess }}
              </div>
          </div>
          <div class="bg-red-500 text-white font-bold rounded-t px-2 py-2" v-if="this.error.length>0" >
              <div class="" role="alert">
                  {{ this.error }}
              </div>
            </div>
      </div>
        <form @submit.prevent="submitForm" class="bg-green-300 shadow-md rounded px-8 pb-3 mt-2 mb-4">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div class="mb-2">
              <label for="id" class="block text-gray-700 text-sm font-bold mb-2">Customer Mobile:</label>
              <input type="text" v-on:keyup="fetchProductData" id="id" v-model="product.mobile" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
            </div>
            <div class="mb-2">
              <label for="userService" class="block text-gray-700 text-sm font-bold mb-2">Customer Service:</label>
              <select v-model="product.userService" id="category" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required>
                <option value="" disabled selected>Select a category</option>
                <option v-for="userService in internetCategories" :value="userService" :key="userService">{{ userService }}</option>
              </select>
            </div>
            <div class="mb-2">
              <label for="ci" class="block text-gray-700 text-sm font-bold mb-2">Customer Configuration Item/Link:</label>
              <input type="text" id="ci" v-model="product.ci" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
            <div class="mb-2">
              <label for="paymentstatus" class="block text-gray-700 text-sm font-bold mb-2">Payment Status:</label>
              <select v-model="product.paymentstatus" id="status" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required>
                <option value="" disabled selected>Select a category</option>
                <option v-for="paymentstatus in accountStatus" :value="paymentstatus" :key="paymentstatus">{{ paymentstatus }}</option>
              </select>
            </div>
            <div class="mb-2">
                <label for="amount" class="block text-gray-700 text-sm font-bold mb-2">Amount Paid:</label>
                <input type="text" id="amount" v-model="product.amount" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
              </div>
              <div class="mb-2">
                <label for="transactionid" class="block text-gray-700 text-sm font-bold mb-2">Transaction Id:</label>
                <input type="text" id="transactionid" v-model="product.transactionid" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
              <div class="mb-2">
                <label for="start_bill" class="block text-gray-700 text-sm font-bold mb-2">Start Bill</label>
                <input type="date" id="start_bill" v-model="product.start_bill" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
              <div class="mb-2">
                <label for="next_billing" class="block text-gray-700 text-sm font-bold mb-2">Next Billing:</label>
                <input type="date" id="next_billing" v-model="product.next_billing" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
          
            <div class="flex justify-center mt-2">
              <button type="submit" class="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <span v-if="!isLoading">Create Payment</span>
                <span v-else class="spinner-border text-dark"></span>
              </button>
            </div>
          </div>
          
        </form>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        createsucess:"",
        error:"",
        isLoading:false,
        userId:"",
        file:null,
        product: {
            mobile: "",
            ci: "",
            userService: "",
            paymentstatus: "",
            amount: "",
            start_bill: "",
            next_billing: "",
            transactionid:"",
            },
      // Categories data array for internet Packages in Vue.js
        internetCategories :[
        '3 Mbps',
        '5 Mbps',
        '8 Mbps',
        '10 Mbps',
        '15 Mbps',
        '20 Mbps',
        // Add more categories as needed
        ],
        // data array for account status in Vue.js
        accountStatus :[
        'pending',
        'paid',
        'partially paid',
        // Add more categories as needed
        ],


      };
    },
    methods: {
      async submitForm() {
        this.isLoading=true;
        this.userId=this.hasUserId;
        const formData = new FormData();
        formData.append('ci', this.product.ci);
        formData.append('mobile', this.product.mobile);
        formData.append('paymentstatus', this.product.paymentstatus);
        formData.append('amount', this.product.amount);
        formData.append('userService', this.product.userService);
        formData.append('transactionid', this.product.transactionid);
        formData.append('start_bill', this.product.start_bill);  
        formData.append('next_billing', this.product.next_billing);  
        formData.append('created_by', this.userId);   
        try {
          const config = {
               headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           await axios.post(process.env.VUE_APP_BASE_URL+`/payment/v1/add-payment`,formData,config)
           .then(response => {
               this.isLoading=false;
               this.responseCode=response.data.statusCode;
               console.log(response);
               if(this.responseCode === "4000"){
                this.createsucess=response.data.msg;
                  this.product = {
                    mobile: "",
                    ci: "",
                    userService: "",
                    paymentstatus: "",
                    amount: "",
                    start_bill: "",
                    next_billing: "",
                    transactionid:"",
                    };
                this.$router.push('/payments');
               }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
              console.log(error); 
           });  
        } catch (error) {
          console.error('API Error:', error);
        }
      },
      async  fetchProductData() {

      const productId = this.product.mobile;
      try {
          const config = {
               headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           this.userProduct={
            id:productId
           }
           if(this.product.mobile.length<10){
            this.error="Mobile number must be 10 digits";
           }
           else{
           await axios.post(process.env.VUE_APP_BASE_URL+`/expert/v1/user-products`,this.userProduct,config)
           .then(response => {
            this.isLoading=false;
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){
                var products=response.data.data;
                this.product={
                    mobile: products.mobile,
                    ci: products.ci,
                    userService: products.userService,
                    paymentstatus: "",
                    amount: "",
                    start_bill: products.start_bill,
                    next_billing: products.next_billing,
                    transactionid:"",
                }
                this.createsucess="";
                this.userProduct={
                id:""
           }
               }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
            console.log(error);
           });  
          }
        } catch (error) {
          console.error('API Error:', error);
        }
    },
      
    },
    computed:{
      hasUserId(){
      return this.$store.state.authUser.userId;
    }
    }
  };
  </script>
  
  <style>
  /* Add your custom styles here */
  </style>
  