import { createRouter, createWebHistory } from 'vue-router'
// import About from './pages/AboutView'
import HomeView from './pages/HomeView'
import store from './store/index'
import CreateUser from './pages/users/CreateUser'
import LoginView from './pages/accounts/LoginView'
import CreateAccount from './pages/accounts/CreateView'
import ProductsView from './pages/products/ProductsView'
import CreateProduct from './pages/products/CreateProduct'
import EditProduct from './pages/products/EditProduct'
import ProductView from './pages/products/ProductView'
import ServicesView from './pages/services/ServicesView'
import AllProducts from './pages/products/AllProducts'
import Userproducts from './pages/products/UserProducts'
import CreateService from './pages/services/CreateService'
import Myservices from './pages/services/MyServices'
import EditService from './pages/services/EditService'
import ServiceView from './pages/services/ServiceView'
import UserServices from './pages/services/UserServices'
import ProfileView from './pages/accounts/ProfileView'
import AllPayments from './pages/payments/AllPayments.vue'
import AddPayment from './pages/payments/CreatePayment.vue'
import EditPayment from './pages/payments/EditPayment.vue'
import ViewPayment from './pages/payments/PaymentView'
import NotificationViewVue from './pages/notification/NotificationView.vue'
const routes = [
    { path: '/', component: HomeView,meta:{middleware: 'auth'}, },
    // { path: '/about', component: About,meta:{middleware: 'auth'}, },
    // { path: '/about#contact', component: About,meta:{middleware: 'auth'}, },
    { path: '/adduser', component: CreateUser , meta:{middleware: 'auth'}, },
    { path: '/products', component: AllProducts , meta:{middleware: 'auth'}, },
    { path: '/myproducts', component: ProductsView , meta:{middleware: 'auth'}, },
    { path: '/add-product', component: CreateProduct , meta:{middleware: 'auth'}, },
    { path: '/add-service', component: CreateService , meta:{middleware: 'auth'}, },
    { path: '/myservices', name: 'my-services', component: Myservices , meta:{middleware: 'auth'}, },
    { path: '/edit-product', name: 'edit-product',component: EditProduct , meta:{middleware: 'auth'}, },
    { path: '/edit-service', name: 'edit-service',component: EditService , meta:{middleware: 'auth'}, },
    { path: '/view-product', name: 'view-product',component: ProductView , meta:{middleware: 'auth'}, },
    { path: '/view-service', name: 'view-service',component: ServiceView , meta:{middleware: 'auth'}, },
    { path: '/user-products', name: 'user-products',component: Userproducts , meta:{middleware: 'auth'}, },
    { path: '/user-services', name: 'user-services',component: UserServices , meta:{middleware: 'auth'}, },
    { path: '/profile', name: 'user-profile',component: ProfileView , meta:{middleware: 'auth'}, },
    { path: '/services', component: ServicesView , meta:{middleware: 'auth'}, },
    { path: '/payments',name: 'payments', component: AllPayments , meta:{middleware: 'auth'}, },
    { path: '/add-payment',name: 'add-payments', component: AddPayment , meta:{middleware: 'auth'}, },
    { path: '/edit-payment',name: 'edit-payment', component: EditPayment , meta:{middleware: 'auth'}, },
    { path: '/view-payment',name: 'view-payment', component: ViewPayment , meta:{middleware: 'auth'}, },
    { path: '/notifications',name: 'notifications', component: NotificationViewVue , meta:{middleware: 'auth'}, },
    { path: '/login', component: LoginView, },
    { path: '/account', component: CreateAccount, },
]
const router = createRouter(
    {
        history: createWebHistory(),
        routes,
    }
);
router.beforeEach((to,_,next)=>{
    if(to.meta.middleware){
        const middleware = require(`./middleware/${to.meta.middleware}`);
        if(middleware){
            middleware.default(to,next,store);
        }
    }else{
        next();
    }

})
export default router;