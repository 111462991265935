<template>
    <div class="flex justify-center items-center h-screen">
      <div class="w-full sm:w-2/4">
        <h2 class="text-2xl font-bold mb-4">Edit Product</h2>
        <div>
          <div v-if="this.createsucess.length>0">
              <div class="alert alert-success" role="alert">
                  {{ this.createsucess }}
              </div>
          </div>
          <div class="bg-red-500 text-white font-bold rounded-t px-2 py-2" v-if="this.error.length>0" >
              <div class="" role="alert">
                  {{ this.error }}
              </div>
            </div>
      </div>
        <form @submit.prevent="updateProduct" class="bg-green-300 shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div class="mb-4">
            <label for="productName" class="block text-gray-700 text-sm font-bold mb-2">Product Name:</label>
            <input type="text" id="productName" v-model="product.productName" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
          </div>
          <div class="mb-4">
            <label for="productDesc" class="block text-gray-700 text-sm font-bold mb-2">Product Description:</label>
            <textarea id="productDesc" v-model="product.productDesc" rows="4" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required></textarea>
          </div>
          <div class="mb-4">
            <label for="productPrice" class="block text-gray-700 text-sm font-bold mb-2">Product Price:</label>
            <input type="number" id="productPrice" v-model="product.productPrice" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required/>
          </div>
          <div class="mb-4">
            <label for="location" class="block text-gray-700 text-sm font-bold mb-2">Location:</label>
            <input type="text" id="location" v-model="product.location" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div class="mb-4">
            <label for="category" class="block text-gray-700 text-sm font-bold mb-2">Category:</label>
            <select v-model="product.category" id="category" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required>
              <option value="" disabled selected>Select a category</option>
              <option v-for="category in categories" :value="category" :key="category">{{ category }}</option>
            </select>
          </div>
          <div class="flex justify-center mt-4">
            <button type="submit" class="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              <span v-if="!isLoading">Update Product</span>
              <span v-else class="spinner-border text-dark"></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        createsucess:"",
        error:"",
        isLoading:false,
        userId:"",
        file:null,
        product: {
          productName: "",
          productDesc: "",
          productPrice: "",
          location: "",
          category: ""
        },
        userProduct:{
            id:""
           },
           categories: [
        '3 Mbps',
        '5 Mbps',
        '8 Mbps',
        '10 Mbps',
        '15 Mbps',
        '20 Mbps',
        // Add more categories as needed
      ]
      };
    },
    created() {
    // Fetch the product data using the productId from route params
    this.fetchProductData();
  },
    methods: {
      onFileChange(event) {
      this.file = event.target.files[0];
    },
      async updateProduct() {
        this.isLoading=true;
        this.userId=this.hasUserId;
        const formData = new FormData();
        formData.append('productName', this.product.productName);
        formData.append('productDesc', this.product.productDesc);
        formData.append('productPrice', this.product.productPrice);
        formData.append('location', this.product.location);
        formData.append('category', this.product.category);
        formData.append('userId', this.userId);
        formData.append('file', this.file);
        formData.append('id', this.$route.query.productId);
  
        try {
          const config = {
               headers: {
               'Content-Type': 'multipart/form-data',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };

           await axios.post(process.env.VUE_APP_BASE_URL+`/product/v1/update`,formData,config)
           .then(response => {
            this.isLoading=false;
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){
                this.createsucess=response.data.msg;
                  this.product = {
                  productName: "",
                  productDesc: "",
                  productPrice: "",
                  location: "",
                  category: "",
                  userId: ""
                };
                this.$router.push('/myproducts');
               }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
              console.log(error); 
           });  
        } catch (error) {
          console.error('API Error:', error);
        }},
      async  fetchProductData() {
      const productId = this.$route.query.productId;
      this.userId=this.hasUserId;
      try {
          const config = {
               headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
               'x-router-id': process.env.VUE_APP_ROUTER_ID,
               'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
               }
           };
           this.userProduct={
            id:productId
           }
           await axios.post(process.env.VUE_APP_BASE_URL+`/product/v1/user-product`,this.userProduct,config)
           .then(response => {
            this.isLoading=false;
               this.responseCode=response.data.statusCode;
               if(this.responseCode === "4000"){
                var products=response.data.data;
                this.product={
                    productName:products.productName,
                    productDesc: products.productDesc,
                    productPrice: products.productPrice,
                    location: products.location,
                    category: products.category
                }
                this.createsucess="";
                this.userProduct={
                userId:"",
                id:""
           }
           console.log(this.product);
               }else if(this.responseCode === "4001"){
                  this.error=response.data.msg;
                  this.isLoading=false;
               }
           })
           .catch(error => {
            console.log(error);
           });  
        } catch (error) {
          console.error('API Error:', error);
        }

    },
      
    },
   
    computed:{
      hasUserId(){
      return this.$store.state.authUser.userId;
    }
    }
  };
  </script>
  
  <style>
  /* Add your custom styles here */
  </style>
  