<template>
  <footer class="w-full py-4 text-center contfooter text-white">
    <div class="container-fluid">
      <p>&copy; {{ currentYear }} FibreManager@. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style>
.contfooter {
  background-color: darkblue; 
  background-size: cover;
  background-position: center;
}

</style>