<template>
    <div class="flex justify-center items-center">
      <div class="w-full sm:w-4/4">
        <div class="flex justify-center m-2">
          <input
            v-model="searchTerm"
            @input="filterProducts"
            placeholder="Search products..."
            class="p-2 m-3 rounded border border-gray-300"
          />
          <div class="flex m-2" v-if="hasUserId && hasUserRole === 'Admin'">
            <div class="flex justify-end m-2">
              <button @click="addProduct" class="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Add Payment
              </button>
            </div>
          </div>
        </div>
        <!-- Product list -->
        <div v-if="loading" class="text-center">Loading...</div>
        <div v-else>
          <div class="flex flex-wrap -mx-4 overflow-x-auto table-container p-2">
            <table class="table-auto w-full border-collapse">
              <thead class="">
                <tr class="bg-gray-200">
                  <th class="border px-3 py-2">BillID</th>
                  <th class="border px-3 py-2">MOBILE</th>
                  <th class="border px-3 py-2">CI</th>
                  <th class="border px-3 py-2">Service</th>
                  <th class="border px-3 py-2">Payment Status</th>
                  <th class="border px-3 py-2">Amount</th>
                  <th class="border px-3 py-2">Transaction ID</th>
                  <th class="border px-3 py-2">Start Bill</th>
                  <th class="border px-3 py-2">Next Billing</th>
                  <th class="border px-3 py-2">View</th>
                  <th class="border px-3 py-2">Edit</th>
                  <th class="border px-3 py-2">Delete</th>
                </tr>
              </thead>
              <tbody>
  
                <tr v-for="product in filteredProducts" :key="product._id" >
                  <td class="border px-3 py-2">{{ product.id }}</td>
                  <td class="border px-3 py-2">{{ product.mobile }}</td>
                  <td class="border px-3 py-2">{{ product.ci }}</td>
                  <td class="border px-3 py-2">{{ product.userService }}</td>
                  <td class="border px-3 py-2">{{ product.paymentstatus }}</td>
                  <td class="border px-3 py-2">{{ product.amount }}</td>
                  <td class="border px-3 py-2">{{ product.transactionid }}</td>
                  <td class="border px-3 py-2">{{ product.start_bill }}</td>
                  <td class="border px-3 py-2">{{ product.next_billing }}</td>
                  <td class="border px-3 py-2"><div class="flex items-center">
                    <font-awesome-icon icon="eye" @click="viewProduct(product)" class="cursor-pointer text-blue-500 mr-2" style="font-size: 24px;"/>
                  </div></td>
                  <td class="border px-3 py-2"><div class="flex items-center">
                    <font-awesome-icon icon="edit" @click="editProduct(product)" class="cursor-pointer text-yellow-500 mr-2"  style="font-size: 24px;"/>
                  </div></td>
                  <td class="border px-3 py-2"><div class="flex items-center">
                    <font-awesome-icon icon="trash" @click="deleteProduct(product.id)" class="cursor-pointer text-red-500 mr-2" style="font-size: 24px;" />
                  </div></td>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        productUser:"",
        responseCode:"",
        products: [],
        loading: true,
        filteredProducts: [],
        searchTerm: '',
        
      };
    },
    mounted() {
      // Fetch the product data from the API when the component is mounted
      this.fetchProducts();
      
    },
    methods: {
      filterProducts() {
      this.filteredProducts = this.products.filter((product) => {
        // You can modify this condition based on the properties you want to search in
        return (
          product.id.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          product.userService.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          product.transactionid.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          product.paymentstatus.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      });
    },
      async fetchProducts() {
        try {
                 const config = {
                 headers: {
                 'Content-Type': 'application/json',
                 'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
                 'x-router-id': process.env.VUE_APP_ROUTER_ID,
                 'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
                 }
             };
             await axios.get(process.env.VUE_APP_BASE_URL+`/payment/v1/payments`,config)
             .then(response => {
                 this.responseCode=response.data.statusCode;
                 if(this.responseCode === "4000"){
                    this.isLoading=false;
                  this.products = response.data.data;
                  this.filteredProducts=response.data.data;
                 }else if(this.responseCode === "4001"){
                    this.error=response.data.msg;
                    this.isLoading=false;
                 }
             })
             .catch(error => {
                console.log(error);
                this.isLoading=false;
             });
          this.loading = false;
        } catch (error) {
          // Handle API error
          console.error('API Error:', error);
          this.loading = false;
        }
      },
      viewProduct(product){
        this.$router.push({ name: 'view-payment', query: { billId: product.id } });
      },
  
      myProducts(){
        this.$router.push({name:'my-services'});
      },
      editProduct(product){
          this.$router.push({ name: 'edit-payment', query: { billId: product.id } });
        },
        addProduct(){
          this.$router.push('/add-payment');
        },
        async confirmDeleteProduct(product) {
          const confirmDelete = window.confirm('Are you sure you want to delete this product?');
          if (confirmDelete) {
            await this.deleteProduct(product.id);
          } else {
            // User canceled, do nothing
          }
        },
        async deleteProduct(id){
          const confirmDelete = window.confirm('Are you sure you want to delete this product?');
          if (confirmDelete) {
            const config = {
                   headers: {
                   'Content-Type': 'application/json',
                   'Authorization': 'Basic ' + btoa(process.env.VUE_APP_MY_SECRET_USERNAME+":"+process.env.VUE_APP_MY_SECRET_PASSWORD),
                   'x-router-id': process.env.VUE_APP_ROUTER_ID,
                   'x-source-system': process.env.VUE_APP_SOURCE_SYSTEM
                   }
               };
               this.removeProduct={
                id:id
               };
               await axios.post(process.env.VUE_APP_BASE_URL+`/payment/v1/remove`,this.removeProduct,config)
               .then(response => {
                   this.responseCode=response.data.statusCode;
                   if(this.responseCode === "4000"){
                      this.removeProduct={
                          id:""
                       }; 
                      this.products = this.products.filter(product => product.id !== id);
                      this.filteredProducts = this.filteredProducts.filter(product => product.id !== id);
                   }else if(this.responseCode === "4001"){
                      this.loginsucess=response.data.msg;
                   }
               })
               .catch(error => {
                  console.log(error);
                  
               });
          } else {
            // User canceled, do nothing
          }
        }
    },
    computed:{
      hasUserId(){
      return this.$store.state.authUser.userId;
    },
    hasUserRole(){
        return this.$store.state.authUser.userRole;
    }
    }
  };
  </script>
  
  <style>
  /* Style for the table container */
  .table-container {
    overflow-x: auto;
  }
  /* Additional styling for the table cells */
  .table-auto th,
  .table-auto td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  </style>
  